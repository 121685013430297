{
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;

}
html.body {
  display: block;
  /*margin-right: auto;*/
  /*margin-left: auto;*/
}

body {
  /*width: 90%;*/
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(198, 30, 156,0.08);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h3 {
  font-size: 1.5rem;
  color: rgb(56,56,56);
  line-height: 2.5rem;
  margin: 2rem 0rem;
}
h4 {
  font-size: 1rem;
  color: rgb(56,56,56);
}
a {
  color: rgb(56,56,56);
}

